.container {
  max-width: 36rem;
  padding: 0 1rem;
  margin: 0 auto 6rem;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 30px;
  width: 200px;
}

.headerImage {
  width: 6rem;
  height: 6rem;
}

.headerHomeImage {
  width: 8rem;
  height: 8rem;
}

.backToHome {
  margin: 3rem 0 0;
  font-size: medium;
}

@media only screen and (max-width: 600px) {
  .container {
    margin: 0;
  }

  .headerHomeImage {
    width: 8rem;
    height: 4.5rem;
  }
}
